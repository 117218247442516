import "../css/BodyStruct.css"
import DOMPurify from "dompurify";
export default function BodyStruct(inputValues)
{
    const htmlInjectCode = inputValues.htmlContent;
    const sanitizedData = () => ({
        __html: DOMPurify.sanitize(htmlInjectCode)
    })
    return(
        <div className="body-box">
            <div dangerouslySetInnerHTML={sanitizedData()}></div>
        </div>
    )
}