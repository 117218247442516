import Navbar from "./components/Navbar";
import FooterBar from "./components/FooterBar";
import BodyStruct from "./components/BodyStruct";
import HeroSection from "./components/HeroSection";
export default function GitLayout()
{
    document.title = "Gladstone Git";
    return(
        <>
        <Navbar/>
        <HeroSection header="Gladstone Git Service" body=""/>
        <BodyStruct htmlContent="<h1>What is the Gladstone Git Service</h1> <p>Provided as a community service and for the sake of hosting relevant materials provided is a git system. Hosted here will be resources and bespoke software some subject to restrictions that require accounts. Watch this space and be aware of things to come it this service is a platform for more to come. </p> <p><a href=https://git.gladstonesystems.com/>GIT Main Site</a></p> <p>Please note at this time the git service is open to shall issue account registration. This will transfer to may issue in the times to come often with conditions. This is for various reasons hence I suggest getting in early. </p>"/>      
        <FooterBar/>
        </>
    )
}