import "../css/GallerySystem.css"
import "../css/GalleryTemp.css"
export default function GallerySystem()
{
    return(
        <div className="body">
            <div class="responsive">
                <div class="gallery">
                <a target="_blank" href="http://themisakanetwork.com/gallery/1.png">
                    <img src="http://themisakanetwork.com/gallery/1.png" alt="The Bluff Lookout at Wirrabara"/>
                </a>
                    <div class="desc">The Bluff Lookout, Wirrabara overlooking the ocean!</div>
                </div>
            </div>		
            <div class="responsive">
                <div class="gallery">
                <a target="_blank" href="http://themisakanetwork.com/gallery/2.png">
                    <img src="http://themisakanetwork.com/gallery/2.png" alt="The Bluff Lookout Port Pirie "/>
                </a>
                    <div class="desc">The Bluff Lookout, Wirrabara overlooking the Port Pirie!</div>
                </div>
            </div>
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/3.png">
                <img src="http://themisakanetwork.com/gallery/3.png" alt="Gladstone Rail Track!"/>
            </a>
                <div class="desc">Gladstone Rail Track Standard Gauge 1435mm</div>
            </div>
            </div>								
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/4.png">
                <img src="http://themisakanetwork.com/gallery/4.png" alt="Former Bank and Happy Rock Cafe!"/>
            </a>
                <div class="desc">Bank and Cafe Street View(somewhat awful shot)</div>
            </div>
            </div>		
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/5.png">
                <img src="http://themisakanetwork.com/gallery/5.png" alt="Gladstone Street View!"/>
            </a>
                <div class="desc">A view of Gladstone’s main street and main road</div>
            </div>
            </div>		
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/6.png">
                <img src="http://themisakanetwork.com/gallery/6.png" alt="Part of the Port Broughton Collection!"/>
            </a>
                <div class="desc">Port Broughton, Sun Down</div>
            </div>
            </div>
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/7.png">
                <img src="http://themisakanetwork.com/gallery/7.png" alt="Part of the Port Broughton Collection!"/>
            </a>
                <div class="desc">Port Broughton water front</div>
            </div>
            </div>	
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/8.png">
                <img src="http://themisakanetwork.com/gallery/8.png" alt="Part of the Port Broughton Collection!"/>
            </a>
                <div class="desc">Port Broughton main street from jetty</div>
            </div>
            </div>		
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/9.png">
                <img src="http://themisakanetwork.com/gallery/9.png" alt="Part of the Port Broughton Collection!"/>
            </a>
                <div class="desc">Port Broughton Island</div>
            </div>
            </div>						
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/10.png">
                <img src="http://themisakanetwork.com/gallery/10.png" alt="Part of the Port Broughton Collection!"/>
            </a>
                <div class="desc">Port Broughton Jetty</div>
            </div>
            </div>		
            <div class="responsive">
            <div class="gallery">
            <a target="_blank" href="http://themisakanetwork.com/gallery/gallery.zip">
                <img src="http://themisakanetwork.com/gallery/11.png" alt="Download them all?"/>
            </a>
                <div class="desc">Want these and more download them for personal use.</div>
            </div>
            </div>	
        </div>
    )
}