import Navbar from "./components/Navbar";
import FooterBar from "./components/FooterBar";
import GallerySystem from "./components/GallerySystem";
export default function GalleryLayout()
{
  document.title = "Gladstone Gallery";
    return(
      <>
        <Navbar/>
        <GallerySystem/>
        <FooterBar/>
      </>
    )
}