import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router,Switch,Route, Routes } from "react-router-dom";

import HomeLayout from "./HomeLayout";
import GitLayout from "./GitLayout";
import ServicesLayout from "./ServicesLayout";
import ContactLayout from "./ContactLayout";
import GalleryLayout from "./GalleryLayout";
import PortFolio from "./Portfolio";
import Debug from "./Debug";

function App(){
  document.title = "Gladstone Systems";
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLayout/>} />
        <Route path="/home" element={<HomeLayout/>} />
        <Route path="/git" element={<GitLayout/>} />
        <Route path="/services" element={<ServicesLayout/>} />
        <Route path="/gallery" element={<GalleryLayout/>} />
        <Route path="/contact" element={<ContactLayout/>} />
        <Route path="/portfolio" element={<PortFolio/>} />
        <Route path="/debug" element={<Debug/>} />
      </Routes>
    </>
  );
}

export default App;