import "../css/Navbar.css"

import React,{useState} from 'react';
import { SidebarData } from "./SidebarData";
import {Link, useMatch, useResolvedPath} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
/* 
	https://youtu.be/I2UBjN5ER4s?t=1326

	https://www.youtube.com/watch?v=CXa0f4-dWi4
	https://youtu.be/CXa0f4-dWi4?t=483
	https://github.com/mdbootstrap/react-hero
*/
export default function Navbar(){
	// Main menu logic
	const [click,setClick] = useState(false);
	const handleClick = () => setClick(!click);
	// Menu bar logic
	const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    return (
    <nav className="navbar">
		<div className="navbar-container">
			<a className="site-title">Gladstone Systems</a>
			<ul>
				<CustomLink to="/home">Home</CustomLink>
				<CustomLink to="/git">Git</CustomLink>
				<CustomLink to="/services">Services</CustomLink>
				<CustomLink to="/gallery">Gallery</CustomLink>
				<CustomLink to="/contact">Contact</CustomLink>
				<Link to="#" >
            		<FaIcons.FaBars onClick={showSidebar} />
        		</Link>
			</ul>
		</div>

		<nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
				{SidebarData.map((item, index) => {
					return (
						<li key={index} className={item.cName}>
							<Link to={item.path}>
								{item.icon}
								<span>{item.title}</span>
							</Link>
						</li>
					);
				})}
            </ul>
        </nav>
	</nav>
    )
}


function CustomLink({ to, children, ...props }) {
	const resolvedPath = useResolvedPath(to)
	const isActive = useMatch({ path: resolvedPath.pathname, end: true })
	return (
		<li className={isActive ? "active" : ""}>
			<Link to={to} {...props}>
				{children}
			</Link>
		</li>
	)
}