export const SidebarData = [
  {
    title: "Home",
    path: "/home",
    cName: "nav-text",
  },
  {
    title: "Git",
    path: "/git",
    cName: "nav-text",
  },
  {
    title: "Services",
    path: "/services",
    cName: "nav-text",
  },
  {
    title: "Gallery",
    path: "/gallery",
    cName: "nav-text",
  },
  {
    title: "Contact",
    path: "/contact",
    cName: "nav-text",
  },
      
];
