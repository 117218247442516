import axios from "axios";
import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import FooterBar from "./components/FooterBar";
import BodyStruct from "./components/BodyStruct";


export default function ContactLayout()
{
  document.title = "Gladstone Contact";
  const [data, setData] = useState({
    htmlString:'',
    client_ip:''
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
        const response = await axios.get('https://themisakanetwork.com:5000/api/contact');
        setData(response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const handleButtonClick = () => {
    // Redirect to the desired page when the div-button is clicked
    //navigate('https://themisakanetwork.com:5000/api/portfolio');
    window.location.href = 'https://gladstonesystems.com/portfolio';
  };

  if(data.htmlString !== "")
  {
    return(
      <>
        <Navbar/>
        <BodyStruct htmlContent={data.htmlString}/>
        <div onClick={handleButtonClick} role="button">
          <BodyStruct htmlContent={'<h1>Click HERE!</h1> <p>If you wish to obtain more information about my history and qualifications please feel free to see my preliminary portfolio!</p>'}/>
        </div>
        <FooterBar/>
      </>
    )
  }
  setTimeout(() => {
    if(data.htmlString === "")
    {
      return(
        <>
          <Navbar/>
          <BodyStruct htmlContent="<p>Well this is embarrassing our backend is broken you can still however contact us at glads@themisakanetwork.com</p>"/>
          <FooterBar/>
        </>
      )
    }
  }, 2000);
}

