import React from 'react';
import "../css/HeroSection.css"
function HeroSection (inputValues) 
{
  return (
    <>
      <div className="hero-section">
        <div className="hero-content">
            <h1 className="hero-title">{inputValues.header}</h1>
            <p className="hero-subtitle">{inputValues.body}</p>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
