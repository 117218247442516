import axios from "axios";
import Navbar from "./components/Navbar";
import { useState, useEffect } from "react";
import BodyBox from "./components/BodyBox";
import FooterBar from "./components/FooterBar";
import HeroSection from "./components/HeroSection";

export default function PortFolio()
{
    const [data, setData] = useState({
        h1:'',
        h2:'',
        background:'',
        experience:'',
        tech:'',
        works:''
    });
    useEffect(() => {
        fetchData();
      }, []);
    
    const fetchData = async () => {
    try {
        const response = await axios.get('https://themisakanetwork.com:5000/api/json/portfolio.json');
        setData(response.data);
    } 
    catch (error) {
        console.error('Error fetching data:', error);
    }
    };

    if(data.h1 !== '')
    {
        return (
            <>
                <Navbar/>
                <HeroSection header={data.h1} body={data.h2}/>
                <BodyBox htmlMarkup={data.background} colorBackground="rgb(8,86,158)" colorText="white"/>
                <BodyBox htmlMarkup={data.experience} colorBackground="white" colorText="black"/>
                <BodyBox htmlMarkup={data.tech} colorBackground="rgb(8,86,158)" colorText="white"/>
                <BodyBox htmlMarkup={data.works} colorBackground="white" colorText="black"/>
                <FooterBar/>
            </>
        )
    }
    setTimeout(() => {    
        return(
        <>
            <Navbar/>
            <BodyBox htmlMarkup="<h1>IP or Error</h1> <p>Our backend tells us that your ip is out of range or there might be a bug!</p>" colorBackground="white" colorText="black"/>
        </>
        )
    }, 2000);
}