import axios from "axios";
import DOMPurify from "dompurify";
import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";

export default function Debug()
{
    //"https://themisakanetwork.com:5000/api/ipcheck"
    //"https://themisakanetwork.com:5000/api/dynamicsafe/index.txt"
    const [content, setContent] = useState('');
    const [ipState1, setIPState1] = useState('');
    const [ipState2, setIPState2] = useState('');
    useEffect(() => {

            axios.get("https://themisakanetwork.com:5000/api/dynamicsafe/index.txt", { withCredentials: true })
            .then((response) => {
            // Update the state with the response data
            setContent(response.data);
            })
            .catch((error) => {
            console.error('Error:', error);
            });
            
            axios.get("https://themisakanetwork.com:5000/api/ipcheck", { withCredentials: true })
            .then((response) => {
            // Update the state with the response data
            setIPState1(response.data);
            })
            .catch((error) => {
            console.error('Error:', error);
            });

            axios.get("https://themisakanetwork.com:5000/api/ischecked", { withCredentials: true })
            .then((response) => {
            // Update the state with the response data
            setIPState2(response.data);
            })
            .catch((error) => {
            console.error('Error:', error);
            });            

    }, []); 
    const htmlClean = content;
    const cleanData = () => ({
      __html: DOMPurify.sanitize(htmlClean)
    })

   return(
    <>
      <Navbar/>
      <p>{ipState1}</p>
      <p>{ipState2}</p>
    </>
   )
}