import "../css/BodyBox.css"
import DOMPurify from "dompurify";
// https://reactgo.com/react-render-html/
export default function BodyBox(inputValues)
{
    const style = {
        backgroundColor: inputValues.colorBackground,
        height: inputValues.boxHeight,
    };

    const titleStyle =
    {
        color: inputValues.colorText,
    };

    const textStyle = 
    {
        color: inputValues.colorText,
    };
    const htmlInjectCode = inputValues.htmlMarkup;
    const sanitizedData = () => ({
        __html: DOMPurify.sanitize(htmlInjectCode)
    })

    return (
        <>
            <div className="BodyBox" style={style}>
                <div style={titleStyle} dangerouslySetInnerHTML={sanitizedData()}></div>
                <h1 style={titleStyle}>{inputValues.header}</h1>
                <p style={textStyle}>{inputValues.content}</p>
            </div>
        </>
      );
}