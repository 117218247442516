import axios from "axios";
import { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import BodyBox from "./components/BodyBox";
import FooterBar from "./components/FooterBar";
import HeroSection from "./components/HeroSection";
export default function ServicesLayout()
{
  document.title = "Gladstone Services";
  const [data, setData] = useState({
    city:'',
    region:'',
    country:''
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://themisakanetwork.com:5000/api/geolocate');
      setData(response.data);
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  if(data.region === 'South Australia' && data.country === 'Australia')
  {
    return(
      <>
        <Navbar/>
        <HeroSection header="Gladstone Systems Services Offered" body=""/>
        <BodyBox header="Call Out IT Service:" content="Living regional should not mean a lack of access to high quality IT services. The sole proprietorship that is Gladstone Systems is here in part to help to address that.  While offering reasonable rates expansive range of services along with clear policy and integrity." colorBackground="white" colorText="black" boxHeight=""/>
        <BodyBox header="Scope and Services Offered" content="Within the scope of this service are repairs, diagnostics, retrofitting, setup and upgrades of household IT systems. These include but are not limited to TV systems, desktops, laptops, smart devices and consumer electronics. Advice, internet problems, slow boot times, low system performance, device setup, security advice and guidance. All such things are well within scope but are not necessarily the limit of what is offered. For clarification don’t be afraid to drop a line a great many things are possible. Broadly speaking all manners related to house hold computers and electronics are within scope." colorBackground="rgb(8,86,158)" colorText="white" boxHeight=""/>
        <BodyBox header="Costings, Conditions and Policy" content="Gladstone and Georgetown are exempted in full from call out and all other locations charged by distance and time. Distance is directly dictated by fuel costs and time is the hourly rate of $50 AUD and calculated according to distance via public roads. Eg $40 per 100km with estimate of 1 hour of travel would be $50 as per hourly rate which would total $90. All services, their scope and associated fee(s) are payable upon completion according to previous agreement. In the process of these services and settlement we commit to professionalism, privacy and best practices. It is our intention to act in good faith at all times and we kindly request the same in return. Availability is Monday to Saturday 9 to 5 with options for overtime at a premium on a case by case basis." colorBackground="white" colorText="black" boxHeight=""/>      
        <BodyBox header="Data, Accounts and Privacy" content="As a rule Gladstone Systems will not retain, access or solicit information that is not useful or relevant in delivering an agreed to service. The digital or information of any computer or system are strictly the providence of the owner unless our services are relevant to them. In short we have no interest in snooping around on your system and we would also advise you change passwords. In any context where anyone but yourself has knowledge of them suspected or otherwise." colorBackground="rgb(8,86,158)" colorText="white" boxHeight=""/>
        <BodyBox header="Service Delivery and Range" content="On the same basis as call out minus the associated call out fee, is delivery by phone, email or other means as required and subject to negotiation and discretion. Generally speaking call out is the preferred method of delivery. As it regards range all locations within 70km of Gladstone SA are served. This includes but is NOT limited to the call out exempt Gladstone SA and Georgetown SA. Crystal Brook, Jamestown, Port Pirie, Laura, Redhill, Port Broughton, Wirrabara, Melrose and Port Germein as examples. Discretion is applicable to locations beyond 70km or during times nor explicitly mentioned previous subject to negotiation and conditions." colorBackground="white" colorText="black" boxHeight=""/>   
        <FooterBar/>
      </>
    )
  }
  else
  {
  setTimeout(() => {
      return(
        <>
          <Navbar/>
          <HeroSection header="Gladstone Systems Service Out of Range" body="This might be a mistake but our systems have detected the system you are on is far outside of range. Please insure that you aren’t using a VPN or proxy, either way you can still drop us a line via the contact section for more information."/>
          <FooterBar/>    
        </>
      )

  }, 2000);
  }
}