import Navbar from "./components/Navbar";
import FooterBar from "./components/FooterBar";
import BodyBox from "./components/BodyBox";
import HeroSection from "./components/HeroSection";

export default function HomeLayout()
{
  document.title = "Gladstone Home";
  return(
    <>
      <Navbar/>
      <HeroSection header="Welcome to Gladstone Systems" body="We serve Gladstone, the Southern Flinders Ranges and we serve others where we can, living regional and living remote should not imply lack of service. It should not imply great cost delays, poor quality and pain that many other organisations offer. The sole proprietorship that GS is no nonsense with clear and open fee’s and policy. With a mind to also offering service to the community in many ways. If you are in the South Flinders Ranges or even beyond you are in the right place if you need help."/>
      <BodyBox header="Call Out Services" content="We offer a call out service for the Southern Flinders Ranges Area for matters such as. Diagnostics, buying, security advice, back ups and general servicing works. If it involves computer systems than chances we can assist in fixing a great many problems you might have. " colorBackground="rgb(8,86,158)" colorText="white" boxHeight="30vh" />
      <BodyBox header="Software" content="We offer git services to select developers and members in advancement to the communities interest!" colorBackground="white" colorText="black"  boxHeight="30vh" />
      <BodyBox header="Drop us a line" content="If you live within the Southern Flinders Ranges and you face issues with computers or similar systems be it game consoles or TV. There is a very good chance we can help and we are local so you don’t need to wait long! Visit our contact page or learn more through this website!" colorBackground="rgb(8,86,158)" colorText="white"  boxHeight="30vh"/>      
      <FooterBar/>
    </>    
  )
}